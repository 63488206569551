export const reactPortfolioData = [
  {
    id: 1,
    title: "Character Search",
    description:
      "Uses a react hook to fetch API information into a table related to star wars character data",
    techUsed: "React, TypeScript",
    image: "./reactprj1.png",
  },
];
